import React from "react";
import Slider from "react-slick";
// import "react-modal-video/css/modal-video.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ServiceWithTestimonials = ({ testimonialsData }) => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="block-sec">
      <div
        className="background bg-img pt-100 pb-0 parallaxie"
        style={{ backgroundImage: `url(/img/bg-vid.jpg)` }}
        data-overlay-dark="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-5 offset-lg-1">
              <div className="testim-box">
                <div className="head-box">
                  <h6 className="wow fadeIn" data-wow-delay=".5s">
                    Our Clients
                  </h6>
                  <h4 className="wow fadeInLeft" data-wow-delay=".5s">
                    What Client&apos;s Say?
                  </h4>
                </div>
                <Slider
                  {...settings}
                  className="slic-item wow fadeInUp slick-wrapper"
                  data-wow-delay=".5s"
                >
                  {testimonialsData?.testimonials &&
                    testimonialsData?.testimonials?.edges?.map((val, i) => (
                      <div className="item" key={i}>
                        <p>{val?.node?.clientTestimonial}</p>
                        <div className="info">
                          <div className="img">
                            <div className="img-box">
                              <img loading ="lazy" src={val?.node?.clientImage?.guid} alt=""   />
                            </div>
                          </div>
                          <div className="cont">
                            <div className="author">
                              <h6 className="author-name ">
                                {val?.node?.clientName}
                              </h6>
                              <span className="author-details">
                                {val?.node?.clientDesignation}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceWithTestimonials;

import React, { useEffect, useState } from "react";

const CaseStudySlider = () => {
  return (
    <>
      <div className="container case-studies-container section-padding pt-0">
        <div className="row p-0">
          <div className="col-md-12 p-0">
          <h4 class="ind color-font text-center">Case studies</h4>
            <div class="accordion px-3" id="accordionExample">
              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingOne"
                >
                    <div
                      class="btn btn-link btn-block text-left brand-name show collapse py-3"
                      type="div"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Mindfull Eating
                    </div>

                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                    <div class="timeline">
                      <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                        <div class="flip-card">
                          <div class="flip-card-inner">
                            <div class="flip-card-front">
                              <div className="brand-img">
                                <img style={{maxWidth:'max-content'}} src="/img/marketing-case-studies/logos/mindful.webp"/>
                              </div>
                              <h3 style={{bottom:'60px',position:'relative'}}>About Brand</h3>
                              <p>Hover to know more</p>
                            </div>
                            <div class="flip-card-back">
                              <p style={{textAlign:'left'}}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam labore id in. Ad voluptatum quo minus neque sequi id ipsum sunt reprehenderit ab soluta, itaque reiciendis nulla consectetur accusamus temporibus.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                        <div class="timeline__event__icon ">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/challenges.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content ">
                          <h6>Challenges</h6>
                          <div class="timeline__event__description">
                            <p>On time quality content and reel creation.</p>
                            <p>Consistent posting.</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">

                            <img src="/img/marketing-case-studies/icon/target.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <h6>Goals</h6>
                          <div class="timeline__event__description">
                            <p>Increase reach and followers.</p>
                            <p>Have viral contents.</p>
                            <p>Reach right audience.</p>
                            <p>Establish Unique Personal Brand that resonates with the audience</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/problem-solving.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title">
                            Solution
                          </div>
                          <div class="timeline__event__description">
                            <p>Establish Unique Personal Brand that resonates with the audience.</p>
                            <p>Content scheduling per audience engagement</p>
                          </div>

                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/implement.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title">
                            Implementation
                          </div>
                          <div class="timeline__event__description">
                            <p>Daily reel with Thursday stories.</p>
                            <p>Digital marketer, creative head and video editor alignment with client.</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                        <div class="timeline__event__icon">
                          <i class="lni-slim"></i>
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/results.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title ">
                            Results
                          </div>
                          <div class="timeline__event__description">
                            <p>Insta follower reach to <span className="color-font">14K.</span> </p>
                            <p>Average reel views reached to <span className="color-font">  4K.</span></p>
                            <p>More viral content reaching million views</p>
                          </div>

                        </div>
                      </div>
                    </div>
                    </div>                      
                  </div>
                </div>
              </div>

              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingOne"
                >
                    <div
                      class="btn btn-link btn-block text-left brand-name show collapse py-3"
                      type="div"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Mindfull Eating
                    </div>

                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                    <div class="timeline">
                      <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                        <div class="flip-card">
                          <div class="flip-card-inner">
                            <div class="flip-card-front">
                              <div className="brand-img">
                                <img style={{maxWidth:'max-content'}} src="/img/marketing-case-studies/logos/mindful.webp"/>
                              </div>
                              <h3 style={{bottom:'60px',position:'relative'}}>About Brand</h3>
                              <p>Hover to know more</p>
                            </div>
                            <div class="flip-card-back">
                              <p style={{textAlign:'left'}}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam labore id in. Ad voluptatum quo minus neque sequi id ipsum sunt reprehenderit ab soluta, itaque reiciendis nulla consectetur accusamus temporibus.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                        <div class="timeline__event__icon ">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/challenges.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content ">
                          <h6>Challenges</h6>
                          <div class="timeline__event__description">
                            <p>On time quality content and reel creation.</p>
                            <p>Consistent posting.</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">

                            <img src="/img/marketing-case-studies/icon/target.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <h6>Goals</h6>
                          <div class="timeline__event__description">
                            <p>Increase reach and followers.</p>
                            <p>Have viral contents.</p>
                            <p>Reach right audience.</p>
                            <p>Establish Unique Personal Brand that resonates with the audience</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/problem-solving.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title">
                            Solution
                          </div>
                          <div class="timeline__event__description">
                            <p>Establish Unique Personal Brand that resonates with the audience.</p>
                            <p>Content scheduling per audience engagement</p>
                          </div>

                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/implement.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title">
                            Implementation
                          </div>
                          <div class="timeline__event__description">
                            <p>Daily reel with Thursday stories.</p>
                            <p>Digital marketer, creative head and video editor alignment with client.</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                        <div class="timeline__event__icon">
                          <i class="lni-slim"></i>
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/results.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title ">
                            Results
                          </div>
                          <div class="timeline__event__description">
                            <p>Insta follower reach to <span className="color-font">14K.</span> </p>
                            <p>Average reel views reached to <span className="color-font">  4K.</span></p>
                            <p>More viral content reaching million views</p>
                          </div>

                        </div>
                      </div>
                    </div>
                    </div>                      
                  </div>
                </div>
              </div>

              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingOne"
                >
                    <div
                      class="btn btn-link btn-block text-left brand-name show collapse py-3"
                      type="div"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Mindfull Eating
                    </div>

                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                    <div class="timeline">
                      <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                        <div class="flip-card">
                          <div class="flip-card-inner">
                            <div class="flip-card-front">
                              <div className="brand-img">
                                <img style={{maxWidth:'max-content'}} src="/img/marketing-case-studies/logos/mindful.webp"/>
                              </div>
                              <h3 style={{bottom:'60px',position:'relative'}}>About Brand</h3>
                              <p>Hover to know more</p>
                            </div>
                            <div class="flip-card-back">
                              <p style={{textAlign:'left'}}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam labore id in. Ad voluptatum quo minus neque sequi id ipsum sunt reprehenderit ab soluta, itaque reiciendis nulla consectetur accusamus temporibus.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                        <div class="timeline__event__icon ">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/challenges.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content ">
                          <h6>Challenges</h6>
                          <div class="timeline__event__description">
                            <p>On time quality content and reel creation.</p>
                            <p>Consistent posting.</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">

                            <img src="/img/marketing-case-studies/icon/target.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <h6>Goals</h6>
                          <div class="timeline__event__description">
                            <p>Increase reach and followers.</p>
                            <p>Have viral contents.</p>
                            <p>Reach right audience.</p>
                            <p>Establish Unique Personal Brand that resonates with the audience</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/problem-solving.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title">
                            Solution
                          </div>
                          <div class="timeline__event__description">
                            <p>Establish Unique Personal Brand that resonates with the audience.</p>
                            <p>Content scheduling per audience engagement</p>
                          </div>

                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/implement.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title">
                            Implementation
                          </div>
                          <div class="timeline__event__description">
                            <p>Daily reel with Thursday stories.</p>
                            <p>Digital marketer, creative head and video editor alignment with client.</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                        <div class="timeline__event__icon">
                          <i class="lni-slim"></i>
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/results.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title ">
                            Results
                          </div>
                          <div class="timeline__event__description">
                            <p>Insta follower reach to <span className="color-font">14K.</span> </p>
                            <p>Average reel views reached to <span className="color-font">  4K.</span></p>
                            <p>More viral content reaching million views</p>
                          </div>

                        </div>
                      </div>
                    </div>
                    </div>                      
                  </div>
                </div>
              </div>

              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingOne"
                >
                    <div
                      class="btn btn-link btn-block text-left brand-name show collapse py-3"
                      type="div"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Mindfull Eating
                    </div>

                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                    <div class="timeline">
                      <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                        <div class="flip-card">
                          <div class="flip-card-inner">
                            <div class="flip-card-front">
                              <div className="brand-img">
                                <img style={{maxWidth:'max-content'}} src="/img/marketing-case-studies/logos/mindful.webp"/>
                              </div>
                              <h3 style={{bottom:'60px',position:'relative'}}>About Brand</h3>
                              <p>Hover to know more</p>
                            </div>
                            <div class="flip-card-back">
                              <p style={{textAlign:'left'}}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam labore id in. Ad voluptatum quo minus neque sequi id ipsum sunt reprehenderit ab soluta, itaque reiciendis nulla consectetur accusamus temporibus.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                        <div class="timeline__event__icon ">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/challenges.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content ">
                          <h6>Challenges</h6>
                          <div class="timeline__event__description">
                            <p>On time quality content and reel creation.</p>
                            <p>Consistent posting.</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">

                            <img src="/img/marketing-case-studies/icon/target.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <h6>Goals</h6>
                          <div class="timeline__event__description">
                            <p>Increase reach and followers.</p>
                            <p>Have viral contents.</p>
                            <p>Reach right audience.</p>
                            <p>Establish Unique Personal Brand that resonates with the audience</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/problem-solving.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title">
                            Solution
                          </div>
                          <div class="timeline__event__description">
                            <p>Establish Unique Personal Brand that resonates with the audience.</p>
                            <p>Content scheduling per audience engagement</p>
                          </div>

                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                        <div class="timeline__event__icon">
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/implement.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title">
                            Implementation
                          </div>
                          <div class="timeline__event__description">
                            <p>Daily reel with Thursday stories.</p>
                            <p>Digital marketer, creative head and video editor alignment with client.</p>
                          </div>
                        </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                        <div class="timeline__event__icon">
                          <i class="lni-slim"></i>
                          <div class="timeline__event__date">
                            <img src="/img/marketing-case-studies/icon/results.png" alt="" />
                          </div>
                        </div>
                        <div class="timeline__event__content">
                          <div class="timeline__event__title ">
                            Results
                          </div>
                          <div class="timeline__event__description">
                            <p>Insta follower reach to <span className="color-font">14K.</span> </p>
                            <p>Average reel views reached to <span className="color-font">  4K.</span></p>
                            <p>More viral content reaching million views</p>
                          </div>

                        </div>
                      </div>
                    </div>
                    </div>                      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudySlider;
